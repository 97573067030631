.subpage--partenaires {
  .intro {
    width: 66%;

    @media (max-width:1199px) {
      width: 83%;
    }
    @media (max-width:991px) {
      width: 100%;
    }

    .intro__anchor-links {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin-top: 40px;
    }
  }
}