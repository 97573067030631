.page--centers {

  .grid {
    display: grid;
    grid-template-columns: 75% 25%;
    column-gap: 30px;

    @media (max-width: 991px) {
      grid-template-columns: 100%;
      row-gap: 40px;
    }
  }
  .intro__title {
    margin-bottom: 30px;
    width: 100%;
  }

  .intro__content {
    margin-bottom: 45px;

    p {

      a {
        border-bottom: 2px solid black;
        padding-bottom: 5px;
      }
    }

    .center__file {
      display: flex;
      flex-direction: column;
      width: fit-content;
      margin-top: $large;

      .flex {
        display: flex;
        align-items: baseline;
        gap: 20px;
        padding: 0 5px;

        .bloc {
          gap: 10px;
          display: flex;
          align-items: baseline;
        }

        span {
          color: $color-medium-grey;
        }
      }
    }
  }

  .intro__image {
    max-width: 270px;
    height: auto;

    @media (max-width: 576px) {
      max-width: 100%;
    }

    .image__link {
      display: flex;
      gap: 10px;
      align-items: baseline;
      margin-top: 20px;
    }
  }

  .centers {
    display: flex;
    flex-direction: column;
    gap: 30px;
    list-style-type: none;
    margin-top: 90px;

    @media (max-width:767px) {
      gap: 60px;
    }

    .center {
      border: $border-regular solid black;
      display: grid;
      grid-template-columns: 250px auto;

      @media (max-width:767px) {
        grid-template-columns: 100%;
        gap: 0;
      }

      .center__img {
        width: 250px;
        overflow: hidden;
        display: block;

        @media (max-width:767px) {
          width: 100%;
          max-height: 250px;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
          transition: all .2s linear;
        }

        &:hover {

          img {
            transform: scale(1.02);
          }
        }
      }

      .center__flex {
        display: flex;
        padding: 30px;
        gap: 50px;

        @media (max-width:1199px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }

      .center__texts {
        width: calc(50% - 25px);

        @media (max-width:1199px) {
          width: 100%;
        }

        .center__title, .tags {
          margin-bottom: 20px;
        }

        .base {
          width: fit-content;
          display: inline-block;
          margin-top: 25px;
        }
      }

      .center__dates {
        list-style-type: none;
        gap: 20px;
        width: calc(50% - 25px);

        @media (max-width:1199px) {
          width: 100%;
        }

        @media (max-width:991px) {
          display: none;
        }

        .btn {
          margin-top: 40px;
          margin-left: auto;
        }
      }
    }
  }
}