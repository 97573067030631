.page--activities {

  .introduction__text {

    p {
      margin-bottom: 30px;
      strong {
        font-size: $big;
        font-family: $font-title;
        font-weight: 700;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .introduction__image {
    display: flex;
    flex-direction: column;
    .underline--hover {
      display: inline-block;
      width: fit-content;
    }
    img {
      width: 100%;
    }

    a {
      margin-top: 15px;
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}
