.page--homepage {
  
  //COMPOSITION
  .home-pageheader {
    margin-top: 65px;
    margin-bottom: 110px;
    width: 100%;
    max-width: 80%;
    margin-inline: auto;


    //COMPOSITION 1
    .composition-1 {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 30px;

      .texts__title {
        font-size: clamp(1.333rem, 2vw, 1.667rem);
      }

      .composition__image {
        height: auto;

        img {
          &:nth-child(2) {
            display: none;
          }

          @media (max-width:991px) {
            &:first-child {
              display: none;
            }

            &:nth-child(2) {
              display: initial;
            }
          }
        }
      }

      .composition__text-link {
        .texts__container {
          margin-top: 0;
        }
      }
    }

    //COMPOSITION 2
    .composition-2 {
      display: grid;
      gap: 30px;
      grid-template-columns: 1fr 1fr;

      .texts__title {
        font-size: clamp(1.333rem, 2vw, 1.667rem);
      }

      .composition__text-link {
        grid-column: 1 / 3;
        

        .texts__container {
          margin: 0;
          height: auto;
        }
      }
    }

    //COMPOSITION 3
    .composition-3 {
      display: grid;
      gap: 30px;
      grid-template-columns: 1fr 1fr;

      .texts__title {
        font-size: clamp(1.333rem, 2vw, 1.667rem);
      }

      .composition__text-link {

        .texts__container {
          margin: 0;
          height: 100%;
        }
      }
    }

    //COMPOSITION 4
    .composition-4 {
      display: grid;
      gap: 30px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto 1fr;

      .texts__title {
        font-size: clamp(1.333rem, 2vw, 1.667rem);
      }

      .poster-1 {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
      }

      .composition__text-link {
        position: relative;

        .texts__container {
          margin-top: 0;
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          overflow-y: auto;
        }
      }

      .composition__image {
        height: auto;
      }
    }

    //COMPOSITION 5
    .composition-5 {
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(2, 1fr);
    }

    //COMPOSITION6
    .composition-6 {
      display: grid;
      gap: 30px;
      grid-template-columns: 41% 33% 25%;
      grid-template-rows: auto 1fr;
      width: calc(100% - 50px);

      @media (max-width:991px) {
        width: 100%;
      }

      .poster-1 {
        grid-column: 1 / 2;
        grid-row: 1 / 3;

        img {
          height: auto;
          object-fit: contain;
          object-position: top;
        }
      }

      .composition__text-link {
        position: relative;

        .texts__container {
          margin-top: 0;
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          overflow-y: auto;
        }
      }

      .composition__image {
        height: auto;
      }

      .image-1, .composition__video {
        grid-column: 2 / 4;
      }
    }

    @media (max-width: 991px) {
      margin-bottom: 80px;
      margin-top: 30px;

      .composition-1,
      .composition-2,
      .composition-3,
      .composition-4,
      .composition-5,
      .composition-6 {
        display: flex;
        flex-direction: column;
        gap: 30px;
      }
    }


    // Image
    .composition__image {
      width: 100%;
      height: 500px;

      &.poster {
        height: fit-content;
        display: flex;

        a {
          display: flex;
        }

        img {
          height: auto;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.img-mobile {
        display: none;

        @media (max-width:991px) {
          display: block;

          img {
            display: initial;
          }
        }
      }
    }

    //Video
    .composition__video {
      width: 100%;
      padding-top: 56.25%;
      position: relative;
      background-color: black;
      overflow: hidden;

      video, iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.mobile-vid {
        display: none;
      }

      @media (max-width:991px) {
        display: none;

        &.mobile-vid {
          display: block;
        }
      }
    }

    // Link with image
    .composition__image-link {
      position: relative;

      .text-image__container {
        padding: 30px 20px 35px 20px;
        display: flex;
        gap: 30px;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;

        img {
          display: flex;
          height: auto;
          width: 100%;
          max-width: 200px;
        }
      }

      &:after {
        content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='auto' height='13' viewBox='0 0 61 116' fill='none'><path d='M60.5 49.4478L0.5 0.5L0.499994,31.9794L34.3973,58.182L0.499985,84.2025L0.49998,115.5L60.5,66.5522L60.5,49.4478Z' fill='black'/></svg>");
        display: inline;
        font-family: "Font Awesome 6 Regular", sans-serif;
        position: absolute;
        right: 25px;
        bottom: 15px;
      }
    }

    // Link with text
    .composition__text-link,
    .composition__simple-link {
      .texts__container {
        padding: 30px 20px 35px 20px;
        position: relative;
        margin-top: 25px;

        .texts__title {
          margin-bottom: 10px;
          margin-top: 0;
        }

        @media (max-width:991px) {
          margin-top: 0;
          height: auto !important;
          position: static !important;
        }
      }
    }

    a {
      &.composition__text-link,
      &.composition__simple-link {
        position: relative;

        &:after {
          content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='auto' height='13' viewBox='0 0 61 116' fill='none'><path d='M60.5 49.4478L0.5 0.5L0.499994,31.9794L34.3973,58.182L0.499985,84.2025L0.49998,115.5L60.5,66.5522L60.5,49.4478Z' fill='black'/></svg>");
          display: inline;
          font-family: "Font Awesome 6 Regular", sans-serif;
          position: absolute;
          right: 21px;
          bottom: 18px;
        }
      }
    }

    // Posters
    .composition__posters {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;

      @media (max-width:991px) {
        grid-template-columns: 100%;
        gap: 30px;
      }
    }

    @media (max-width:991px) {

      .mobile-order-1 {
        order: 1;
      }

      .mobile-order-2 {
        order: 2;
      }

      .mobile-order-3 {
        order: 3;
      }

      .mobile-order-4 {
        order: 4;
      }
    }
  }

  //PROGRAMMATION
  .programmation {
    width: 80%;
    margin: 0 auto;

    @media (max-width: 1199px) {
      width: 90%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    .programmation__title {
      @media (max-width: 991px) {
        margin: 0 auto;
        max-width: 470px;
      }
    }

    .list__programs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
      margin-top: 30px;
      list-style-type: none;

      @media (max-width: 991px) {
        grid-template-columns: 100%;
        gap: 35px;
        max-width: 470px;
        margin: 0 auto;
        margin-top: 40px;
      }

      .programm {
        border: $border-regular solid black;
        position: relative;

        .programm__tag {
          color: $white;
          background: black;
          width: fit-content;
          padding: 5px 10px 3.5px 10px;
          position: absolute;
          left: 20px;
          top: -10px;
        }

        .programm__date {
          border-bottom: $border-regular solid black;
          padding: 26px 20px 10px 20px;
          background: $color-primary;
        }

        .programm__title {
          padding: 20px 20px 10px 20px;
        }

        .programm__desc {
          padding: 0 20px 20px 20px;
        }

        .programm__link {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: flex-end;
          align-items: flex-end;
          position: absolute;
          padding: 10px 25px 15px 10px;
          bottom: 0;
          &:after {
            content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='auto' height='13' viewBox='0 0 61 116' fill='none'><path d='M60.5 49.4478L0.5 0.5L0.499994,31.9794L34.3973,58.182L0.499985,84.2025L0.49998,115.5L60.5,66.5522L60.5,49.4478Z' fill='black'/></svg>");
            display: inline;
            font-family: "Font Awesome 6 Regular", sans-serif;
          }
        }
      }
    }
  }

  .btn--more {
    display: flex;
    margin: 0 auto;
    width: fit-content;
    margin-top: 40px;
  }
}

@media (max-width:1199px) {
  .page--homepage {

    .home-pageheader  {
        max-width: 100%;
    }
  }
}
