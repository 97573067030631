.page--intranet {
  .content {
    width: 66.67%;
    margin: 0 auto;
    margin-top: 70px;

    @media (max-width: 1199px) {
      width: 83%;
    }

    @media (max-width:991px) {
      width: 100%;
    }
  }

  .content__text, .content__accordions, .content__link, .content__iframe {
    margin: 25px 0;
  }

  .content__iframe {
    width: 100%;
    height: auto;

    .ics-calendar-label {
      font-weight: 700;
      font-size: $big;
    }

    .ics-calendar-month-grid th {
      background-color: black;
      color: white;
    }

    .ics-calendar-month-grid .day {
      background-color: black;
      color: white;
      border-bottom: 2px solid black;
      border-left: none;
      border-right: none;
      border-top: none;
      text-align: center;
    }

    .ics-calendar-month-grid .events .time {
      font-size: 110%;
      font-weight: 700;
      margin-bottom: 10px;

      .end_time {
        font-size: inherit;
        font-weight: inherit;
      }
    }

    .ics-calendar-month-grid ul.events li.all-day {
      padding: 0;
      background-color: $color-clear-grey;

      .title {
        padding: 0.4em 0.8em;
      }
    }

    .ics-calendar-month-grid .events .all-day-indicator {
      background-color: $color-clear-grey;
      padding: 0.4rem;
      font-weight: 700;
      padding-bottom: 8px;
    }

    .ics-calendar-month-grid ul.events li,
    .ics-calendar-month-grid td,
    .ics-calendar-month-grid {
      border: 2px solid black;
    }

    .ics-calendar-month-grid ul.events li {
      border-left: none;
      border-right: none;
      border-top: none;
      color: black;

      &:last-child {
        border: none;
      }
    }

    .ics-calendar-month-grid .off {
      background-color: white;
      color: black;
      background: repeating-linear-gradient(
        45deg,
        white,
        white 5px,
        $color-grey 5px,
        $color-grey 10px
      );
      opacity: .2;
    }
  }

  .content__link {
    width: fit-content;
  }
}