.page-404 {
  .page-section {
    width: 100%;
    text-align: center;

    .error-details {
      margin-bottom: 20px;
    }

    .error-actions {
      display: flex;
      justify-content: center;
      gap: 20px;
    }
  }
}