.tags {
  display: flex;
  gap: 10px;
  align-items: baseline;
  flex-wrap: wrap;
  .tag {
    width: fit-content;
    background: black;
    color: $white;
    padding: 5px 15px;
    white-space: nowrap;
    font-size: 12px !important;
    margin: 0;
  }
}
