.subpage--press {

  .bloc__contact {
    margin-top: 2.2rem;

    .contact__name {
      font-weight: 700;
      margin-bottom: 1.4rem;
    }

    .infos {
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;
      list-style: none;
    }
  }

  section.blocs {
    margin-top: 7rem;

    @media (max-width:767px) {
      margin-top: 4rem;
    }

    h2.huge {
      font-weight: 700;
      margin-bottom: 2rem;
    }

    .bloc {
      display: grid;
      gap: 1.6rem;
      grid-template-columns: 33% 58%;
      align-items: flex-start;
      margin-bottom: 2rem;

      @media (max-width:767px) {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      &:last-child {
        margin-bottom: 0;
      }

      h3.massive {
        font-weight: 700;
        margin-bottom: .9rem;
      }

      .bloc__text {
        margin-bottom: 1.1rem;
      }

      .date {
        margin-top: 1.1rem;
        color: $color-medium-grey;
      }
    }

    ul.links {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 1.1rem;

      li {

        a {
          display: flex;
          width: fit-content;
          align-items: center;
          justify-content: flex-start;

          &.underline {
            display: inline-block;
          }

          i {
            font-size: $regular;
            margin-right: .5rem;
          }
        }
      }
    }

    &.accordions {

      h2.huge {
        margin-bottom: 0;
      }
    }

    .accordion {
      padding-inline: 0;
      margin: 0;

      .grid {
        display: flex;
        flex-direction: column;
        gap: 35px;
      }
    }

    section {

      h3 {
        font-weight: 700;
        margin-bottom: 1.1rem;
        font-size: $medium;
      }
    }
  }
}