button {
  border: none;
  background: inherit;
  transition: all .2s linear;

  &#place_order {
    background: white !important; //!important for woocommerce usage
    color: $color-text !important; //!important for woocommerce usage
    top: 0;
    left: 0;
    position: relative;
    display: inline-block;
    box-shadow: -6px 6px 0 black !important; //!important for woocommerce usage
    border-radius: 0 !important; //!important for woocommerce usage
    transition: all .15s linear 0s !important; //!important for woocommerce usage
    border: 1px solid black;
    font-weight: 500;
    font-family: $font-base;

    &:hover {
      top: 3px;
      left: -3px;
      box-shadow: -3px 3px 0 black !important;

      &:after {
        top: 0;
        left: -3px;
        width: $angle-hover !important; //!important for woocommerce usage
        height: $angle-hover !important; //!important for woocommerce usage
      }

      &:before {
        bottom: -3px;
        right: 0;
        width: $angle-hover !important; //!important for woocommerce usage
        height: $angle-hover !important; //!important for woocommerce usage
      }
    }

    &:after {
      content: '\0020';
      position: absolute;
      z-index: -1;
      top: 1px;
      left: -5px;
      width: $angle !important; //!important for woocommerce usage
      height: $angle !important; //!important for woocommerce usage
      background-color: black;
      transform: rotate(45deg) !important; //!important for woocommerce usage
      transition: all .15s linear 0s !important; //!important for woocommerce usage
      border: 1px solid black;
    }

    &:before {
      content: '\0020';
      position: absolute;
      z-index: -1;
      bottom: -5px;
      right: 1px;
      width: $angle !important; //!important for woocommerce usage
      height: $angle !important; //!important for woocommerce usage
      background-color: black;
      transform: rotate(45deg);
      transition: all .15s linear 0s;
      border: 1px solid black;
    }
  }
}
span.btn {
  cursor: initial;
}
.btn {
  font-family: $font-base;
  width: max-content;
  border-radius: inherit;
  background: inherit;
  font-size: $medium;
  cursor: pointer;
  border: $border-light solid black !important; //!important for woocommerce usage
  padding: 15px 40px;
  &.btn--smaller-padding {
    padding: 10px 40px;
  }
  &.btn--border {
    background: white !important; //!important for woocommerce usage
    color: $color-text !important; //!important for woocommerce usage
    text-align: center;
    top: 0;
    left: 0;
    position: relative;
    display: inline-block;
    box-shadow: -6px 6px 0 black !important; //!important for woocommerce usage
    border-radius: 0 !important; //!important for woocommerce usage
    transition: all .15s linear 0s !important; //!important for woocommerce usage

    @media (max-width: 767px) {
      width: 100%;
    }

    &:hover {
      top: 3px;
      left: -3px;
      box-shadow: -3px 3px 0 black !important;

      &:after {
        top: 0;
        left: -3px;
        width: $angle-hover !important; //!important for woocommerce usage
        height: $angle-hover !important; //!important for woocommerce usage
      }

      &:before {
        bottom: -3px;
        right: 0;
        width: $angle-hover !important; //!important for woocommerce usage
        height: $angle-hover !important; //!important for woocommerce usage
      }
    }

    &:after {
      content: '\0020';
      position: absolute;
      z-index: -1;
      top: 1px;
      left: -5px;
      width: $angle !important; //!important for woocommerce usage
      height: $angle !important; //!important for woocommerce usage
      background-color: black;
      transform: rotate(45deg) !important; //!important for woocommerce usage
      transition: all .15s linear 0s !important; //!important for woocommerce usage
      border: 1px solid black;
    }

    &:before {
      content: '\0020';
      position: absolute;
      z-index: -1;
      bottom: -5px;
      right: 1px;
      width: $angle !important; //!important for woocommerce usage
      height: $angle !important; //!important for woocommerce usage
      background-color: black;
      transform: rotate(45deg);
      transition: all .15s linear 0s;
      border: 1px solid black;
    }
  }


  &.btn--inherit-woocommerce {
    color: $color-text !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    padding: 15px 40px !important;
    border-radius: inherit !important;
    font-size: $medium !important;
    font-weight: inherit !important;
  }

  &.btn--icon {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  &.btn--100-width {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &.btn--more {
    width: 40px !important; //!important for woocommerce usage
    height: 40px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}