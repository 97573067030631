.woocommerce {
  .actions {
    .coupon {
      display: flex;
      gap: 20px;
      // Code promo
      .woocommerce #content table.cart td.actions .input-text, .woocommerce table.cart td.actions .input-text, .woocommerce-page #content table.cart td.actions .input-text, .woocommerce-page table.cart td.actions .input-text {
        width: min-content;
      }
    }

    button[type="submit"][name="update_cart"] {
      display: none !important;
    }
  }

  .checkout-button.wc-forward {
    background: white !important; //!important for woocommerce usage
    color: $color-text !important; //!important for woocommerce usage
    top: 0;
    left: 0;
    position: relative;
    display: inline-block;
    box-shadow: -6px 6px 0 black !important; //!important for woocommerce usage
    border-radius: 0 !important; //!important for woocommerce usage
    transition: all .15s linear 0s !important; //!important for woocommerce usage
    border: 1px solid black;
    font-weight: 500;
    font-family: $font-base;

    &:hover {
      top: 3px;
      left: -3px;
      box-shadow: -3px 3px 0 black !important;

      &:after {
        top: 0;
        left: -3px;
        width: $angle-hover !important; //!important for woocommerce usage
        height: $angle-hover !important; //!important for woocommerce usage
      }

      &:before {
        bottom: -3px;
        right: 0;
        width: $angle-hover !important; //!important for woocommerce usage
        height: $angle-hover !important; //!important for woocommerce usage
      }
    }

    &:after {
      content: '\0020';
      position: absolute;
      z-index: -1;
      top: 1px;
      left: -5px;
      width: $angle !important; //!important for woocommerce usage
      height: $angle !important; //!important for woocommerce usage
      background-color: black;
      transform: rotate(45deg) !important; //!important for woocommerce usage
      transition: all .15s linear 0s !important; //!important for woocommerce usage
      border: 1px solid black;
    }

    &:before {
      content: '\0020';
      position: absolute;
      z-index: -1;
      bottom: -5px;
      right: 1px;
      width: $angle !important; //!important for woocommerce usage
      height: $angle !important; //!important for woocommerce usage
      background-color: black;
      transform: rotate(45deg);
      transition: all .15s linear 0s;
      border: 1px solid black;
    }
  }
}

// Old form