.page--infos {
  &.template {
    .container {
      &.flex {
        display: flex;
        gap: 8.33%;

        @media (max-width:1199px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 80px;
        }
      }
    }

    .left-side {
      width: 66.66%;

      @media (max-width:1199px) {
        width: 100%;
      }
    }
    .right-side {
      width: 25%;

      @media (max-width:1199px) {
        width: 100%;
      }

      .infos__title {
        font-size: $large;
        font-family: $font-title;
        font-weight: 700;
        margin-bottom: 25px;
      }

      .infos__links {
        margin: 25px 0 35px 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        a {
          width: fit-content;
          display: block;
        }
      }

      .infos__flex {
        display: flex;
        flex-direction: column;

        .infos__file, .infos__link {
          padding: 15px 5px;
          display: flex;
          gap: 10px;
          align-items: baseline;
        }
        .infos__file {
          border-bottom: 1px solid $color-clear-grey;
        }
      }
    }
  }
}