.page--jobs {

  .content {
    width: 66%;
    margin: 0 auto;

    @media (max-width: 1440px) {
      width: 83%;
    }
    @media (max-width:1199px) {
      width: 100%;
    }
  }

  .intro__title {
    margin-top: 70px;
    margin-bottom: 25px;

    @media (max-width: 767px) {
      margin-top: $large;
    }
  }
  .intro__content {
    margin-bottom: 60px;

    @media (max-width: 767px) {
      margin-bottom: $huge;
    }
  }

  .jobs {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: 767px) {
      gap: $large;
    }

    .job {
      border: 2px solid black;
      border-radius: 3px;
      padding: 30px 28px 28px;
      .job__title {
        margin-bottom: 20px;
      }
      .job__arrow {
        height: $big;
        &:after {
          content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='auto' height='13' viewBox='0 0 61 116' fill='none'><path d='M60.5 49.4478L0.5 0.5L0.499994,31.9794L34.3973,58.182L0.499985,84.2025L0.49998,115.5L60.5,66.5522L60.5,49.4478Z' fill='black'/></svg>");
          display: flex;
          justify-content: flex-end;
          font-family: "Font Awesome 6 Regular", sans-serif;
          height: 100%;
        }
      }
    }
  }
}