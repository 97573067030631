.page--contact {
  .introduction {
    display: flex;
    width: 66.66%;
    margin: 0 auto;
    margin-top: 60px;
    gap: 70px;

    @media (max-width: 1199px) {
      width: 80%;
    }
    @media (max-width: 991px) {
      width: 100%;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 40px;
      margin-top: 30px;

      .introduction__left {
        order: 2;
      }
      .introduction__right {
        order: 1;
      }
    }
    .introduction__left, .introduction__right {
      width: 50%;

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .introduction__left {
      img {
        width: 100%;
        max-width: 400px;
        height: auto;
      }
    }

    .introduction__right {
      .introduction__contact {
        margin-bottom: 50px;
        .infos__links {
          div {
            display: flex;
            flex-direction: column;
            gap: 15px;
          }
        }
        .underline--hover {
          display: inline-block;
          width: fit-content;
        }
        .link__arrow {
          display: flex;
          gap: 10px;
          align-items: center;
          padding-right: 10px;
          &:after {
            content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='auto' height='13' viewBox='0 0 61 116' fill='none'><path d='M60.5 49.4478L0.5 0.5L0.499994,31.9794L34.3973,58.182L0.499985,84.2025L0.49998,115.5L60.5,66.5522L60.5,49.4478Z' fill='black'/></svg>");
            display: inline;
            font-family: "Font Awesome 6 Regular", sans-serif;
          }
        }
      }

      .introduction__address {
        .infos__link {
          display: flex;
          gap: 10px;
          align-items: center;
        }
      }

      .introduction__contact, .introduction__address {
        .infos__title {
          display: flex;
          gap: 10px;
          align-items: center;
          margin-bottom: 25px;
          img {
            width: 65px;
          }
        }
        .link {
          margin-top: 25px;
        }
      }

    }
  }
}