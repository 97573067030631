.page--center {
  .center__flex {
    margin-top: 60px;
    display: grid;
    grid-template-columns: 66% 34%;
    gap: 60px;

    @media (max-width:991px) {
      grid-template-columns: 100%;
    }

    .center__flex--left {
      .center__desc {
        margin-bottom: 40px;
      }
      .center__infos {
        display: flex;
        flex-direction: column;

        .center__infos__flex {
          margin-bottom: 25px;
          display: flex;
          align-items: center;
          gap: 5px;
          .center__infos__title {margin: 0;}
          .center__infos__icon {
            img {
              width: 75px;
            }
          }
        }

        .center__infos__content {

          a {
            border-bottom: 2px solid black;
            padding-bottom: 5px;
          }
        }
      }
    }

    .center__flex--right {
      width: calc(100% - 60px);

      @media (max-width:991px) {
        width: 100%;
      }
      ul {
        margin-bottom: 30px;

        .center__date {
          display: block;

          .center__date__availability {
            margin-top: 10px;
            right: 0;
          }
        }
      }

      .btn--border {
        margin-bottom: 20px;
      }
    }
  }
}