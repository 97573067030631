.page--faq {
  .questions {
    width: 80%;
    margin: 70px auto 100px auto;

    @media (max-width: 1199px) {
      width: 100%;
    }
    .accordion {
      padding: 30px 20px 20px 20px;
    }
    .accordion__title {
      font-size: $big;
    }
  }
  .contact {
    margin: 0 auto;
    width: 50%;
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;

    @media (max-width: 1441px) {
      width: 70%;
    }

    @media (max-width: 1199px) {
      width: 100%;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .contact__link {
      height: fit-content;
      white-space: nowrap;
    }
  }
}