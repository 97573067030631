//** Common **//

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-size-adjust: 100%;
  font-size: $base;
  line-height: $line-height;

  &.unscroll {
    overflow: hidden !important;
  }

  @media (max-width: 767px) {
    font-size: $base-mobile;
  }
}

body {
  font-family: $font-base;
  font-weight: 500;
  font-size: 1rem;
  line-height: $line-height;
  color: $color-text;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &.unscroll {
    overflow: hidden !important;
  }

  // &.admin-bar {
  //   margin-top: 32px;

  //   header {

  //     .container {

  //       .header__nav_mobile {
  //         top: 153px;
  //         height: calc(100vh - 153px);
  //       }
  //     }
  //   }
  // }
}

* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

// Fix footer bottom
#main {
  flex: 1 0 auto;
}

.hidden {
  display: none !important;
}


.container {
  width: 80%;
  max-width: 1440px;
  padding: 0 15px;
  
  &.inner-8 {
    width: 53.33%;
    margin: 0 auto;
  }
  
  &.inner-10 {
    width: 66.66%;
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    width: 90%;
  }

  @media (max-width: 576px) {
    width: 100%;
  }

  &.flex {
    display: flex;
    gap: 8.33%;
    flex-wrap: wrap;

    @media (max-width:1199px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 0;
    }
  }
}

.left-side {
  width: 66.66%;

  @media (max-width:1199px) {
    width: 100%;
  }
}

.right-side {
  width: 25%;

  @media (max-width:1199px) {
    width: 100%;
    margin-top: 80px;
  }
}

.underline {
  width: fit-content;

  &:after {
    display: block;
    content: "\0020";
    width: 100%;
    height: 3px;
    border-bottom: $border-regular solid black;
    transition: width .3s;
  }

  &:hover {
    &::after {
      width: 0;
    }
  }
}

.underline--hover {
  &:after {
    display: block;
    content: "\0020";
    width: 0;
    transition: width .3s;
    height: 3px;
    border-bottom: $border-regular solid black;
    margin-top: -15px;
  }

  &.no-margin {
    &:after {
      margin-top: 0;
    }
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
}

p, h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

h5 {
  font-size: $big;
}

strong {
  font-family: $font-title;
  font-weight: 700;
}

.label {
  padding: 7px 15px 5px 15px;
}


// Font-size
.smaller {
  font-size: $smaller; //12px
}

.small {
  font-size: $small; //14px
  @media (max-width: 991px) {
    font-size: $smaller !important;
  }

  @media (max-width:767px) {
    font-size: 0.813rem !important;
  }
}

.common,
.regular {
  font-size: $regular; //16px
  @media (max-width: 767px) {
    font-size: 1rem !important;
  }
}

.base,
#page-content p,
#page-content h6 {
  font-size: $base; //18px
  @media (max-width: 991px) {
    font-size: $regular !important;
  }
  @media (max-width: 767px) {
    font-size: 1rem !important;
  }
}

.medium,
#page-content h5 {
  font-size: $medium; //20px;
  @media (max-width: 991px) {
    font-size: $base !important; //18px
  }
}

.big,
#page-content h4 {
  font-size: $big; //24px
  @media (max-width: 1199px) {
    font-size: $medium !important;
  }
}

.large,
#page-content h3 {
  font-size: $large; //30px
  @media (max-width: 1366px) {
    font-size: $big !important;
  }
}

.massive,
#page-content h2 {
  font-size: $massive; //36px
  @media (max-width: 1366px) {
    font-size: $large !important;
  }
}

.huge {
  font-size: $huge; //40px
  @media (max-width: 1366px) {
    font-size: $massive !important;
  }
}

.gigantic {
  font-size: $gigantic; //50px
  @media (max-width: 1366px) {
    font-size: $huge !important;
  }
}

.smaller, .small, .common, .base, .medium, .big {
  line-height: $line-height;
}

.large, .massive, .huge, .gigantic {
  line-height: $line-height-smaller;
}

.regular {
  font-family: $font-base;
}

.bold {
  font-family: $font-title;
  font-weight: 700;
}

.gfield_required {
  color: $color-medium-grey !important;
}

#page-content {

  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: normal;
  }

  h2 {
    margin-bottom: 0.5rem;
  }

  h6 {
    font-family: $font-title;
    font-weight: 700;
  }

  p {
    margin-bottom: 1.6rem;
  }

  a:not(.button) {
    padding-bottom: 5px;
    border-bottom: 2px solid black;
  }

  .gform_fields {

    .gfield {
      padding-top: 40px;
      label {
        font-size: $regular;
        color: $color-text;
      }
      input {
        border: none;
        border-bottom: $border-light solid $color-medium-grey;
        width: 100%;
        font-size: .889rem;

        &:focus {
          text-decoration: none;
          border-bottom-color: $color-text;
          outline: none;
        }
      }

      .ginput_container {
        input {
          border: none;
          border-bottom: $border-light solid $color-medium-grey;
        }
      }
    }

    fieldset {
      border: none;
      width: 100%;

      .ginput_container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
        grid-gap: 2%;
        grid-auto-flow: dense;

        span {
          display: flex;
          flex-direction: column-reverse;
          padding-top: 40px;
        }

        @media (max-width: 767px) {
          grid-template-columns: 100%;
          gap: 25px;
        }
      }

      legend {
        visibility: hidden;
        height: 0;
      }
    }
  }

  .gform_footer {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
  }
}

.woocommerce-page #page-content {

  a:not(.button) {
    padding-bottom: 0;
    border-bottom: none;
  }
}

iframe {
  max-width: 100%;
}

a img, img {
  border: none;
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all .2s linear;

  &:hover {
    color: inherit;
    outline: none;
    cursor: pointer;
    text-decoration: none;
  }

  &:focus {
    color: inherit;
    outline: none;
    text-decoration: none;
  }

  &:visited {
    color: inherit;
  }
}

.not-loaded {
  display: none;
}

.select2-results__option[data-selected=true] {
  background-color: black !important;
  color: white !important;
}

.select2-results__option--highlighted {
  background-color: #f9f9f9 !important;
  color: black !important;
}

.page-id-8380 {
  .newsletter {
    border-top: 0 !important;
  }
}