.page--job {

  .breadcrumbs {
    margin-top: 0;
    padding: 0 !important;
  }
  .job {
    width: 66%;
    margin: 0 auto;

    @media (max-width: 1440px) {
      width: 83%;
    }
    @media (max-width:1199px) {
      width: 100%;
    }

    .job__intro {
      margin-top: 50px;
      margin-bottom: 40px;

      @media (max-width: 767px) {
        margin-top: $large;
        margin-bottom: $large;
      }
    }

    .job__content {
      h2, h3, h4, h5, h6 {
        font-size: $medium;
        font-family: $font-title;
        font-weight: 700;
      }

      ul, li, p {
        font-size: $base;
        font-family: $font-base;
      }

      ul {
        padding-left: 30px;
      }
    }

    .job__file {
      display: flex;
      flex-direction: column;
      width: fit-content;
      margin-top: $large;

      .flex {
        display: flex;
        align-items: baseline;
        gap: 20px;
        padding: 0 5px;

        .bloc {
          gap: 10px;
          display: flex;
          align-items: baseline;
        }

        span {
          color: $color-medium-grey;
        }
      }
    }

    .job__application {
      .job__application__title {
        margin-top: 100px;
        margin-bottom: $large;

        @media (max-width: 767px) {
          margin-top: 80px;
        }
      }

      .gform_fields {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .gfield__email, .gfield__phone, .gfield__firstname, .gfield__lastname, .gfield__lm, .gfield__cv {
          width: calc(50% - 10px);

          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }

      fieldset {
        border: none;

        .ginput_container {
          span {
            display: flex;
            flex-direction: column-reverse;
            padding-top: 40px;
          }
        }

        legend {
          visibility: hidden;
          height: 0;
        }
      }

      .gform_fields {
        .gfield {
          label {
            font-size: $regular;
            color: $color-medium-grey;
          }

          .ginput_container {
            input {
              border: none;
              border-bottom: $border-light solid $color-medium-grey;
              font-size: $regular;
              width: 100%;
              margin-bottom: 30px;
              padding: 0 10px 10px 10px;

              &:focus {
                outline: none;
                caret-color: black;
              }
            }
          }

          .ginput_container_fileupload {
            display: flex;
            flex-direction: column;

            input {
              font-size: $regular;
              margin-bottom: 0;
            }

            .gform_fileupload_rules {
              font-size: $smaller;
            }
          }
        }

        .gfield_label {
          font-family: $font-title;
          font-weight: 700;
          font-size: $small;
          padding: 0 10px;
        }
      }

      .gform_footer {
        margin-top: 35px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}