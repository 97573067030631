.workshop__date,
.center__date {
  display: grid;
  grid-template-columns: 55% 1fr;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: $border-light solid $color-clear-grey;
  padding: 10px 0;

  @media (max-width:1440px) {
    grid-template-columns: 1fr;
  }

  &:last-child {
    border-bottom: none;
  }

  .workshop__date__availability,
  .center__date__availability {
    padding: 8px 15px 4px 15px;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    position: relative;
    width: 100%;
    text-align: center;

    @media (max-width:1199px) {
      max-width: 50%;
    }

    @media (max-width:991px) {
      max-width: 100%;
    }
  }

  .full {
    background-color: $color-red;
  }

  .last_places {
    background: $color-orange;
  }

  .available {
    background: $color-green;
  }

  .waiting_list {
    background-color: $color-grey;
    right: 20px;
    cursor: pointer;

    &:after {
      content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='auto' height='13' viewBox='0 0 61 116' fill='none'><path d='M60.5 49.4478L0.5 0.5L0.499994,31.9794L34.3973,58.182L0.499985,84.2025L0.49998,115.5L60.5,66.5522L60.5,49.4478Z' fill='black'/></svg>");
      display: inline;
      font-family: "Font Awesome 6 Regular", sans-serif;
      position: relative;
      left: 50px;
      transition: all .2s linear;
    }

    @media (max-width:1440px) {
      right: 0;
    }
  }
}

.workshop-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(131, 131, 131, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 11;
  align-items: center;
  justify-content: center;
  display: none;
  visibility: hidden;

  &.is-active {
    display: flex;
    visibility: visible;
  }

  .container {

    @media (max-width:576px) {
      padding: 0 30px;
    }
  }

  .popup-content {
    position: relative;
    background-color: white;
    border: 2px solid black;
    padding: 50px;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width:576px) {
      padding: 40px;
    }

    .popup-close {
      position: absolute;
      top: 30px;
      right: 30px;
      font-size: 30px;
      cursor: pointer;
    }

    .gform_wrapper {
      margin-top: 40px;
      .gfield {
        margin-bottom: 30px;
        input {
          margin-bottom: 0;
        }
      }
    }
  }

  .workshop_name,
  h2 {
    display: inline-block;
  }

  .workshop_date {
    margin-top: 20px;
    &::before {
      content: "Date : ";
    }
  }

  .gform_footer {
    margin-top: 30px;
    text-align: right;
  }

  .gform_fields {
    .gfield {
      
      label {
        font-size: $regular;
        color: $color-text;
      }
      input {
        border: none;
        border-bottom: $border-light solid $color-medium-grey;
        width: 100%;
        font-size: .889rem;
        margin-bottom: 40px;

        &:focus {
          text-decoration: none;
          border-bottom-color: $color-text;
          outline: none;
        }
      }

      .ginput_container {
        input {
          border: none;
          border-bottom: $border-light solid $color-medium-grey;
        }
      }
    }

    fieldset {
      border: none;
      width: 100%;

      .ginput_container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
        grid-gap: 2%;
        grid-auto-flow: dense;

        span {
          display: flex;
          flex-direction: column-reverse;
        }

        @media (max-width: 767px) {
          grid-template-columns: 100%;
          gap: 25px;
        }
      }

      legend {
        visibility: hidden;
        height: 0;
      }
    }
  }
}

.workshop-subscription-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-align: center;
  font-size: 1.111rem;
  line-height: normal;
  padding: 8px 30px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid black;
  color: black;
  margin-top: 10%;
  white-space: nowrap;
}