//** Footer **//
footer {
  margin-top: 120px;

  @media (max-width: 767px) {
    margin-top: 100px;
  }
}

// FOOTER TOP
.footer__main {
  border-top: $border-large solid black;
  padding: 45px 0;

  .grid {
    padding: 0;
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    border-bottom: $border-light solid $color-grey;

    @media (max-width: 576px) {
      gap: 30px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .footer__infos {
        margin-left: 0 !important;
      }
    }
  }

  .grid-2 {
    padding-top: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;

    @media (max-width: 991px) {
      flex-wrap: wrap;
      gap: 40px;
    }

    @media (max-width: 576px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .footer__status {
    grid-area: top-left;
    display: flex;
    align-items: center;
    gap: 20px;
    display: none !important;
    visibility: hidden !important;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    .medium {
      display: flex;
      text-align: center;
      align-items: center;
      gap: 10px;

      .closed, .open, .classe {
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 20px;
      }
    }

    .closed {
      background: $color-red;
    }

    .open {
      background: $color-green;
    }
    .classe {
      background: $color-orange;
    }
  }

  .footer__infos {
    margin-left: auto !important;
    
    a {
      padding-right: 20px;
      width: 100%;
    }
  }

  .footer__acces {
    a {
      padding-right: 10px;
      width: 100%;
    }
  }

  .footer__address {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .footer__socials {
    .socials {
      list-style: none;
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      grid-auto-flow: column;
      row-gap: 1.111rem;
      column-gap: 0.5rem;
    }
  }

  .footer__contact {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .footer__links {
    display: flex;
    justify-content: space-between;
    .link-page--border {
        padding-top: 15px;
        border-top: $border-light solid $color-grey;
        margin-top: -10px;
    }

    div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;

      a {
        padding-right: 20px;
      }
    }

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  .socials-label {
    padding: 0 0 0 10px;
      font-size: 1rem !important;
  }

  .link-page {
    &.fit-content {
      width: fit-content;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      &:after {
        content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='auto' height='13' viewBox='0 0 61 116' fill='none'><path d='M60.5 49.4478L0.5 0.5L0.499994,31.9794L34.3973,58.182L0.499985,84.2025L0.49998,115.5L60.5,66.5522L60.5,49.4478Z' fill='black'/></svg>");
        display: inline;
        font-family: "Font Awesome 6 Regular", sans-serif;
      }
    }
  }
}

// FOOTER BOTTOM
.footer__minor {
  background: black;
  color: $white;
  padding: 8px 0;

  @media (max-width: 991px) {
    padding: 16px 0;
  }

  .footer__minor--flex {
    display: flex;
    justify-content: space-between;

    @media (max-width: 991px) {
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}

// 321
.powered {
  position: relative;
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;

    #logo-alt-footer-trois {
      fill: #FFD94A;
      transform: scale(1.2);
    }

    #logo-alt-footer-deux {
      fill: #FFD94A;
      transform: scale(1.2);
    }

    #logo-alt-footer-un {
      fill: #FFD94A;
      transform: scale(1.2);
    }

    &:after {
      width: 93px;
    }
  }

  svg {
    overflow: visible;
    width: 50px;
    height: 24px;
    margin: 0 0 0 5px;
    position: relative;
    top: -2px;
    vertical-align: middle;
    width: 34px;

    #logo-alt-footer-trois {
      fill: $color-text;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
      transform: scale(1);
      transform-origin: center;
    }

    #logo-alt-footer-deux {
      fill: $color-text;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
      transform: scale(1);
      transform-origin: center;
    }

    #logo-alt-footer-un {
      fill: $color-text;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
      transform: scale(1);
      transform-origin: center;
    }
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #FFD94A;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}