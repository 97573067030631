
.nav__languages {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
  position: relative;
  transition: $transition-base;
  overflow: hidden;
  text-transform: uppercase;

  &:hover {
    overflow: visible;

    .nav__lang i {
      transform: rotate(180deg);
    }

    .lang--inactives {
      height: auto;
      opacity: 1;
      background: $white;
      z-index: 11;
      list-style-type: none;
      position: absolute;

      li {
        border: $border-regular solid black !important;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }

  li {
    &::before {
      display: none;
    }
  }

  .nav__lang {
    padding: 10px 15px;

    i {
      transform: rotate(0deg);
      transition: $transition-base;
      font-size: $medium;
    }

    &.active {
      cursor: pointer;
      padding: 0 15px 10px 15px;

      i {
        margin-left: 4px;
      }
    }
  }

  .lang--inactives {
    opacity: 0;
    height: 0;
    width: 100%;
    transition: $transition-base;
    position: absolute;
    left: 0;
  }
}
