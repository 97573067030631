// Password
.password {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 40vh;

  .password__title {
    margin-bottom: 20px;
  }

  .post-password-form {
    p:first-child {
      font-size: $large;
      line-height: $line-height;
      margin-bottom: 20px;
    }

    label {
      font-size: $medium;
    }

    .btn {
      @media (max-width: 767px) {
        margin-top: 25px;
      }
    }

    input[type=password] {
      margin: 0 50px 0 10px;
      border: none;
      border-bottom: $border-light solid $color-grey;

      @media (max-width:767px) {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}