.woocommerce form .show-password-input, .woocommerce-page form .show-password-input {
  top: 50%;
  transform: translateY(-50%);
}
.woocommerce {
  .required {
    color: $color-red !important;
  }

  .woocommerce-error {
    border-top-color: $color-red !important;
    border-top: $border-regular solid $color-red !important;

    &:before {
      left: 10px;
      top: initial;
      color: $color-red !important;
    }

    li {
      padding-left: 20px !important;
    }
  }

  .button {
    &.wc-backward {
      padding: 15px 30px !important;
      border-radius: 0 !important;
      border: 2px solid black !important;
      color: black !important;
      background-color: transparent !important;
      font-weight: 500 !important;
      font-family: inherit;
  
      &:hover {
        background-color: black !important;
        color: white !important;
      }
    }
  }
  form {
    input, textarea {
      padding: 5px 10px !important;
      border-radius: 0 !important;
      border: $border-light solid $color-dark-grey;
    }
    h3 {
      font-size: $big;
      font-family: $font-title;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }

  .woocommerce-message,
  .woocommerce-info,
  .woocommerce-error {
    border-top: $border-regular solid black !important;
    background-color: #f9f9f9;
    color: black;
    
    &:before {
      color: black !important;
    }

    .button {
      width: fit-content;
      border: none !important;
      padding: 0 !important;
      font-weight: 500;
      color: black;
      background-color: transparent;

      &:after {
        display: block;
        content: "\0020";
        width: 100%;
        height: 3px;
        border-bottom: $border-regular solid black;
        transition: width .3s;
      }

      &:hover {
        background-color: transparent;
        color: inherit;
        &::after {
          width: 0;
        }
      }
    }
  }
  
  .woocommerce-message {
    border-top: $border-regular solid $color-green !important;

    &:before {
      color: $color-green !important;
    }
  }

  .woocommerce-error {
    border-top: $border-regular solid $color-red !important;

    &:before {
      color: $color-red !important;
    }
  }

  .woocommerce-form-coupon {
    border-radius: 0 !important;
    p:first-child {
      margin: 20px 0;
      padding-left: 6px;
    }
    p.form-row-first {
      margin-bottom: 20px !important;
    }
    p.form-row-last {
      float: initial !important;
      button {
        margin-left: 10px;
      }
    }
  }

  .related.products {
    margin-top: $margin-base;
    clear: both;
    grid-column: span 3;

    @media (max-width:991px) {
      margin-top: 0;
    }

    @media (max-width:767px) {
      width: 100%;
    }

    h2 {
      margin-bottom: 30px;
      margin-top: 0;
    }

    .products {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;
      margin: 0;

      @media (max-width:1199px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width:767px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width:576px) {
        grid-template-columns: 100%;
      }

      &::before,
      &::after {
        display: none;
      }

      li.product {
        float: none;
        width: 100%;
        margin: 0;

        h2 {
          font-weight: bold;
        }

        .price {
          color: black;
        }

        .add_to_cart_button {
          border: 2px solid black;
          color: black;
          background: white;
          padding: 15px 40px;
          border-radius: 0;

          &:hover {
            background-color: black;
            color: white;
          }
        }
      }
    }
  }

  .onsale {
    background-color: $color-primary !important;
  }

  .woocommerce-info {
    border-top-color: $color-primary;
    background-color: $color-clear-grey;

    .showcoupon {
      width: fit-content;
      position: relative;
      color: $color-text !important;

      &:after {
        display: block;
        content: "\0020";
        width: 100%;
        height: 3px;
        border-bottom: $border-regular solid black;
        transition: width .3s;
        position: absolute;
        bottom: -5px;
        left: 0;
      }

      &:hover {
        &::after {
          width: 0;
        }
      }
    }

    &:before {
      color: $color-text;
    }
  }

  .widget_price_filter {
    .ui-slider {
      .ui-slider-range {
        background-color: $color-primary;
      }

      .ui-slider-handle {
        background-color: $color-primary;
      }
    }

    .price_slider_wrapper {
      .ui-widget-content {
        background-color: #ebe9eb;
      }
    }
  }

  div.product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 45px;

    @media (max-width:991px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 60px;
    }

    .product_title {
      line-height: 120%;
      font-size: $gigantic;
      font-family: $font-title;
      font-weight: 700;

      @media (max-width: 991px) {
        font-size: $huge !important;
      }
    }
    .summary {
      width: 100% !important;
      float: none !important;

      .price:first-of-type {
        display: none;
      }

      .woocommerce-variation-price .price {
        display: initial;
        color: black;
        font-weight: bold;
      }

      .info-school,
      .asterisk,
      p {
        line-height: 140%;
        font-size: 1.25rem;

        a {
          border-bottom: 2px solid black;
        }
      }

      .asterisk {
        margin-top: 30px;
      }

      .cart {
        
        .variations,
        .single_variation_wrap {

          th {
            padding: 0;
            text-align: left;
  
            label {
              font-weight: 500;
              font-size: 1rem;
            }
          }

          select {
            border: none !important;
            font-size: $regular;
            box-shadow: 0 2px 11px rgba(160, 160, 160, 0.25);
            border-radius: 3px !important;
            text-align: center;
            padding: 5px 10px;
          }

          .reset_variations {
            font-weight: bold;
          }

          .fooevents-checkout-attendee-info {
            display: none;
          }

          #fooevents_bookings_date_val__trans_field {
            margin-top: 30px;
            margin-bottom: 50px !important;

            label {
              font-weight: 500;
              font-size: 1rem;
              margin-bottom: 10px;
            }
          }

          .quantity {
            margin-bottom: 20px;
            margin-right: 20px;
            
            input {
              border: 2px solid black;
              padding: 15px 10px !important;
              min-height: 55px;
              width: 55px;
              text-align: center;
            }
          }

          .button {
            background-color: white !important;
          }
        }

        .variations {
          border: none !important;
        }

        #wc-bookings-booking-form {
          border: none;
          padding: 0;
        }

        .wc-bookings-date-picker {
          padding: 0;
          border: none;
          margin-top: 60px;

          .ui-datepicker-calendar {
            border: 2px solid black;
          }
        }

        .ui-datepicker-header {
          background-color: black;
          background: black;
        }

        .ui-datepicker-title {
          background-color: black;
          font-family: $font-base;
          border: 2px solid black;
          border-right-color: white;
          border-left-color: white;
          text-transform: capitalize;
        }

        .ui-datepicker-next,
        .ui-datepicker-prev {
          background: black;
          border: 2px solid black;
        }

        .quantity {
          .input-text {
            padding: 5px;
          }
        }

        .wc-bookings-booking-form-button {
          background-color: white;
          color: black;
          border-radius: 0;
          padding: 15px 30px;
          border: 2px solid black !important;
          float: right;

          &.disabled {
            background-color:white;
          }

          &:hover {
            background-color: black;
            color: white;
          }
        }

        .wc-bookings-booking-cost {
          margin: 0;
          background-color: #f9f9f9;
          background: #f9f9f9;
          padding: 20px;
          border: none;
        }

        .button {
          padding: 15px 30px !important;
          border-radius: 0 !important;
          border: 2px solid black !important;
          color: black !important;
          background-color: transparent !important;
          font-weight: 500 !important;
          font-family: inherit;
          opacity: 1 !important;
      
          &:hover {
            background-color: black !important;
            color: white !important;
          }
        }
      }

      .product_meta {
        display: none;
      }
    }

    .images {
      float: none !important;
      width: 100% !important;
    }

    .woocommerce-tabs {
      display: none;
    }

    &.product-type-booking {

      .related.products {
        display: none !important;
        visibility: hidden !important;
      }
    }
  }

  .cart-collaterals {
    margin-top: $margin-base;
  }

  .woocommerce-pagination {
    .page-numbers {
      border: 0 !important;

      li {
        display: inline-block !important;
        border-right: 0 !important;
        float: none !important;

        a, span {
          color: white !important;
          text-decoration: none !important;
          background-color: $color-dark-grey !important;
          cursor: pointer !important;
          padding: 5px 13px !important;
          display: block !important;
          font-size: 14px !important;
          line-height: inherit !important;

          &:hover {
            background-color: $color-primary !important;
            color: #ffffff !important;
          }

          &.current {
            background-color: $color-primary !important;
            color: #ffffff !important;
          }
        }
      }
    }
  }

  .woocommerce-checkout {
    #customer_details {
      margin-bottom: 100px;

      .col-1 {
        max-width: initial !important;
      }

      .col-2 {
        max-width: initial !important;
      }
    }
  }

  .woocommerce-checkout-payment {
    background: none !important;
    border-radius : 0 !important;
    border: $border-regular solid black;

    .wc_payment_method {
      .payment_method_bacs {
        color: $color-text !important;
        background-color: $color-grey !important;
        &:before {
          border: $border-light solid $color-grey !important;
        }
      }

      .payment_box {
        color: $color-text !important;
        background-color: $color-clear-grey !important;

        &::before {
          border-color: $color-clear-grey !important;
          border-left-color: transparent !important;
          border-right-color: transparent !important;
          border-top-color: transparent !important;
        }
      }
    }
  }
}

.woocommerce ul.order_details {
  margin-top: 20px !important;
  margin-bottom: 15px;

  li {
    margin-bottom: 15px;

    strong {
      margin-top: 5px;
    }
  }
}

.woocommerce table {
  border: $border-regular solid black !important;
  border-radius: 0 !important;
  width: 100%;

  .wc-booking-summary {
    border: $border-regular solid black !important;
    border-radius: 0 !important;
  }

  td {
    border-top: none !important;
    border-bottom: 1px solid #f9f9f9 !important;
    padding: 5px 12px !important;
    line-height: 1.3em !important;
    vertical-align: middle;
  }

  tr.order_item {

    td.product-name {
      border-bottom: 1px solid rgba(0,0,0,.1) !important;
    }

    &:last-child {

      td.product-name {
        border-bottom: none !important;
      }
    }
  }
}


.woocommerce .woocommerce-customer-details address {
  border: $border-regular solid black !important;
  border-radius: 0 !important;
  padding: 15px;
}

.woocommerce-order-details {

  .wc-item-meta {
    display: none;
  }
}

.woocommerce-thankyou-order-received {
  line-height: 140%;
  font-size: 1.25rem;
  margin: 50px 0;
}

.woocommerce .woocommerce-order-details,
.woocommerce .woocommerce-customer-details {
  h2 {
    margin-top: 60px;
    margin-bottom: 20px;
    font-size: $big !important;
    font-family: $font-title !important;
    font-weight: 700 !important;
  }

  .wc-booking-summary {
    border: none !important;
    color: black !important;

    .wc-booking-summary-number span {
      display: none;
    }
  }
}

.wc-bookings-booking-form-button.disabled {
  display: none;
}

.woocommerce .woocommerce-error, .woocommerce .woocommerce-message {
  z-index: 0 !important;
}

// CART PAGE
.woocommerce {

  a.remove {
    color: black !important;
    font-size: 1rem;

    &:hover {
      color: inherit !important;
      background: none !important;
    }
  }

  table {
    border: 2px solid black !important;
  
    thead {
      background: black;
      color: white;
    }

    .cart_item {

      .variation {

        .variation-Slot {
          display: none;
        }
      }
    }

    .coupon {

      #coupon_code {
        border: 2px solid black !important;
        width: auto;
        padding: 15px 10px !important;
        font-size: 1rem;
        color: black;
        font-family: inherit;
        text-align: center;

        &::placeholder {
          color: black;
        }
      }
    }

    .button {
      padding: 15px 30px !important;
      border-radius: 0 !important;
      border: 2px solid black !important;
      color: black !important;
      background-color: transparent !important;
      font-weight: 500 !important;
      font-family: inherit;
  
      &:hover {
        background-color: black !important;
        color: white !important;
      }
    }

    form.woocommerce-shipping-calculator {

      .shipping-calculator-button {
        padding-bottom: 3px !important;
        border-bottom: 2px solid black !important;

        &::after {
          display: none !important;
          visibility: hidden !important;
        }
      }
    }
  }

  .checkout-button {
    background-color: white !important;
  }
}

//ORDER PAGE
.woocommerce,
.woocommerce-cart {

  .woocommerce-billing-fields,
  .woocommerce-shipping-fields,
  .woocommerce-additional-fields,
  .woocommerce-address-fields,
  .edit-account,
  .woocommerce-shipping-calculator {
    
    .form-row {
      margin-bottom: 40px !important;
      
      label {
        font-family: $font-title;
        font-weight: 700;
        color: $color-medium-grey;
        font-size: $small;
        padding: 0 10px;
      }

      input:not([type="checkbox"]),
      textarea {
        font-family: $font-base;
        font-weight: 500;
        border: none;
        border-bottom: $border-light solid $color-medium-grey;
        font-size: $regular;
        width: 100% !important;
        padding: 0 10px 10px 10px !important;

        &:focus {
          outline: none;
          caret-color: black;
        }
      }

      select {
        border: none !important;
        font-size: $regular;
        box-shadow: 0 2px 11px rgba(160, 160, 160, 0.25);
        border-radius: 3px !important;
        text-align: center;
        padding: 5px 10px;
      }

    }

    fieldset {
      border: none;
    }

    .button[type="submit"]:not(.btn--border) {
      width: fit-content;
      border: none !important;
      padding: 0 !important;
      font-weight: 500;
      background: transparent;
      font-family: $font-base;
      color: black;

      &:after {
        display: block;
        content: "\0020";
        width: 100%;
        height: 3px;
        border-bottom: $border-regular solid black;
        transition: width .3s;
      }

      &:hover {
        background-color: transparent !important;
        color: black !important;
        &::after {
          width: 0;
        }
      }
    }
  }
}

//acount page
.woocommerce-MyAccount-content {
  width: calc(100% - 300px) !important;
  margin-left: 50px;
  float: right!important;
  max-width: 70% !important;

  @media (max-width:991px) {
    min-width: auto;
    width: 100% !important;
    max-width: 100% !important;
    margin-left: 0;
    margin-top: 60px;

    .woocommerce-Address {
      max-width: 100% !important;
    }
  }

  mark {
    background-color: transparent !important;
    font-weight: bold !important;
  }

  .woocommerce-Address {
    width: 100%;
    max-width: calc(50% - 30px);
    display: inline-block;
    margin-bottom: 50px;
  }
}

.woocommerce-MyAccount-navigation {
  width: 250px !important;
  max-width: 25%;
  min-width: 250px;

  @media (max-width:991px) {
    min-width: auto;
    width: 100% !important;
    max-width: 100%;
  }

  ul {
    list-style: none;
    
    li {
      border-bottom: 2px solid black;

      &.woocommerce-MyAccount-navigation-link--fooevents-tickets,
      &.woocommerce-MyAccount-navigation-link--bookings,
      &.woocommerce-MyAccount-navigation-link--downloads {
        display: none;
        visibility: hidden;
      }

      &:nth-last-child(2),
      &:last-child,
      &.woocommerce-MyAccount-navigation-link--edit-account {
        border-bottom: none;
      }

      a {
        padding: 15px 10px !important;
        display: block;
      }

      &.is-active {

        a {
          color: white;
          background-color: black;
        }
      }

      &:hover {

        a {
          padding-left: 20px;
        }
      }
      &.woocommerce-MyAccount-navigation-link--customer-logout {
        margin-top: 30px;
        
        a {
          font-family: $font-base;
          font-size: $medium;
          border: $border-light solid black !important; //!important for woocommerce usage
          padding: 15px 40px !important;
          background: white !important; //!important for woocommerce usage
          color: $color-text !important; //!important for woocommerce usage
          top: 0;
          left: 0;
          position: relative;
          display: inline-block;
          box-shadow: -6px 6px 0 black !important; //!important for woocommerce usage
          border-radius: 0 !important; //!important for woocommerce usage
          transition: all .15s linear 0s !important; //!important for woocommerce usage

          &:hover {
            top: 3px;
            left: -3px;
            box-shadow: -3px 3px 0 black !important;

            &:after {
              top: 0;
              left: -3px;
              width: $angle-hover !important; //!important for woocommerce usage
              height: $angle-hover !important; //!important for woocommerce usage
            }

            &:before {
              bottom: -3px;
              right: 0;
              width: $angle-hover !important; //!important for woocommerce usage
              height: $angle-hover !important; //!important for woocommerce usage
            }
          }

          &:after {
            content: '\0020';
            position: absolute;
            z-index: -1;
            top: 1px;
            left: -5px;
            width: $angle !important; //!important for woocommerce usage
            height: $angle !important; //!important for woocommerce usage
            background-color: black;
            transform: rotate(45deg) !important; //!important for woocommerce usage
            transition: all .15s linear 0s !important; //!important for woocommerce usage
            border: 1px solid black;
          }

          &:before {
            content: '\0020';
            position: absolute;
            z-index: -1;
            bottom: -5px;
            right: 1px;
            width: $angle !important; //!important for woocommerce usage
            height: $angle !important; //!important for woocommerce usage
            background-color: black;
            transform: rotate(45deg);
            transition: all .15s linear 0s;
            border: 1px solid black;
          }

          @media (max-width:991px) {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}

.woocommerce table.my_account_orders {

  .button {
    width: fit-content;
    border: none !important;
    padding: 0 !important;
    font-weight: 500;

    &:after {
      display: block;
      content: "\0020";
      width: 100%;
      height: 3px;
      border-bottom: $border-regular solid black;
      transition: width .3s;
    }

    &:hover {
      background-color: transparent !important;
      color: black !important;
      &::after {
        width: 0;
      }
    }
  }
}

.woocommerce-pagination {

  .button {
    padding: 15px 30px !important;
    border-radius: 0 !important;
    border: 2px solid black !important;
    color: black !important;
    background-color: transparent !important;
    font-weight: 500 !important;
    font-family: inherit;

    &:hover {
      background-color: black !important;
      color: white !important;
    }
  }
}
.woocommerce-Addresses {
  margin-top: 50px;

  .woocommerce-Address-title {
    font-weight: bold;
    margin-bottom: 15px;
  }

  address {
    font-style: normal;
  }

  .edit {
    width: fit-content;
    border: none !important;
    padding: 0 !important;
    font-weight: 500;

    &:after {
      display: block;
      content: "\0020";
      width: 100%;
      height: 3px;
      border-bottom: $border-regular solid black;
      transition: width .3s;
    }

    &:hover {
      background-color: transparent !important;
      color: black !important;
      &::after {
        width: 0;
      }
    }
  }

  .woocommerce-Address {

    @media (max-width: 768px) {
      margin-bottom: 50px;
    }
  }

}

.woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-1,
.woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-2 {
  max-width: 100%;
}