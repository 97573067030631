.header__nav--options {
  display: flex;
  align-self: flex-end;
  align-items: flex-start;
  position: relative;
  left: 15px;
  gap: 18px;
  margin-right: 50px;
  padding-bottom: .5rem;
  a {
    position: relative;

    //temp
    // display: none;
    // visibility: hidden;

    span.count {
      position: absolute;
      background: black;
      color: white;
      padding: 0px;
      border-radius: 50%;
      width: 27px;
      height: 27px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 21;
      font-size: 12px;
      top: -11px;
      right: -8px;
    }
  }

  a.centers-link,
  a.expos-link {
    display: initial;
    visibility: visible;
    padding: 0 !important;
    font-size: .9rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
    position: relative;
  }

  a.expos-link {
    &::before {
      content: "";
      position: absolute;
      left: -10px;
      top: 0;
      width: 2px;
      height: 100%;
      background-color: black;
    }
  }

  .nav__languages {

    .nav__lang {
      border: none !important;
      position: static;
    }

    .lang {

      a {
        padding: 0 15px;
      }
    }
  }
}

header {
  // SubMenu

  .header__nav--options {
    @media(max-width:1199px) {
      display: none;
    }
  }

  .standard {
    width: 100vw;
    height: auto;

    .container {
      display: block;
      padding: 0 15px;
    }

    .intro__title {
      margin-bottom: 30px;
    }
  
    .intro__content {
      margin-bottom: 45px;
    }
  }
  .hero {
    width: 100vw;

    .hero__background {
      width: 100vw;
      max-width: 1920px;
      margin: 0 auto;
      overflow: hidden;
      height: 360px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 767px) {
        height: 220px;
      }

      img {
        object-fit: cover;
        height: 360px;
        width: 100vw;
        max-width: 1920px;
        object-fit: cover;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;

        @media (max-width: 767px) {
          height: 220px;
        }
      }

      .hero__title {
        color: $white;

        &.hero__title--black {
          color: black;
        }
      }
    }

    .hero__background__tags {

      .hero__title {
        padding-bottom: 0 !important;
        margin-bottom: 20px;
      }

      .flex-column {
        display: flex;
        flex-direction: column;
        gap: 10px;
      
      }
      @media (max-width:767px) {
        height: 280px;
        min-height: 280px;

        img {
          height: 280px;
        }

        .flex-column {
          padding: 70px 15px;
          gap: 0;
        }

        .tags {
          width: 90% !important;
        }
      }

      .tags {
        padding: 0 15px;

        @media (max-width:576px) {
          width: 100% !important;
        }
      }

      @media (max-width:1441px) {
        .tags {
          width: 80%;
          margin: 0 auto;
        }
      }

    }

    .hero__nav_mobile {
      padding: 15px 30px;
      background-color: black;
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      display: none;

      svg {
        transform: rotate(0deg);
        transition: $transition-base;
      }

      &.is-active {

        svg {
          transform: rotate(180deg);
        }
      }

      @media (max-width:1300px) {
        display: flex;
      }
    }

    .hero__nav {
      display: flex;
      justify-content: center;
      flex-direction: row;
      border-top: $border-regular solid black;
      border-bottom: $border-regular solid black;

      &:empty {
        border: none;
      }

      li {
        border-right: $border-regular solid black;
        list-style-type: none;
        text-align: center;
        min-width: 200px;
        transition: all .2s linear;
        &:hover {
          background: black;
          color: $white;
        }

        &:first-child {
          border-left: $border-regular solid black;
        }

        &.current_page_item {
          background: black;
          color: $white;
        }

        a {
          display: block;
          padding: 10px 30px 5px 30px;
          transition: none;
          text-align: center;
        }
      }

      @media (max-width:1300px) {
        overflow: hidden;
        flex-direction: column;
        border: none;
        background-color: white;
        position: absolute;
        width: 100vw;
        transform: scaleY(0);
        transform-origin: top;
        opacity: 0;
        transition: $transition-base;

        &.is-open {
          transform: scaleY(1);
          opacity: 1;
        }

        li {
          border: none;
          padding: 0 30px;
          text-align: left;

          
          &.current_page_item {
            display: none;
          }
          
          a {
            text-align: left;
            padding: 15px 0;
            border-bottom: 2px solid black;
          }
          &:last-of-type {

            a {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  // Primary menu
  .container {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px 50px 15px;
    height: fit-content;

    @media (max-width:1441px) {
      padding-bottom: 30px;
    }

    @media (max-width:1199px) {
      align-items: center;
      padding: 20px 15px;
      padding-bottom: 25px;
    }

    .header__logo {
      position: relative;
      top: 10px;
      width: 225px;
      height: auto;

      @media (max-width:1441px) {
        width: 180px;
      }

      @media (max-width:767px) {
        width: 170px;
      }
    }
    .nav__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: fit-content;
    }

    .mobile-btn-container {
      width: 35px;
      height: 30px;
      position: relative;
      display: none;

      @media (max-width:1199px) {
        display: block;
      }

      .burger-btn {
				width: 100%;
				background-color: black;
				height: 4px;
				margin: 15px 0;
				transition: all .2s linear;

				&:before,
				&:after {
					content: '';
					position: absolute;
					top: -15px;
					left: -15px;
					width: 100%;
					height: 4px;
					background-color: black;
					transition: all .2s linear;
					margin: 15px;
				}

				&:after {
					top: calc(100% - 15px);
				}
			}

			&.open {

				.burger-btn {
					width: 0;

					&:before {
						transform-origin: right;
						transform: rotate(45deg);
						margin-top: 30px;
						top: calc(-50% + 5px);
						left: -20px;
					}

					&:after {
						transform-origin: right;
						transform: rotate(-45deg);
						top: -20px;
						left: -20px;
					}
				}
			}
    }

    .header__nav {
      @media (max-width:1199px) {
        display: none;
      }

      ul.menu {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        position: relative;
        z-index: 10;

        li {
          position: relative;
          list-style-type: none;
          padding: 9px 30px 5px 30px;
          border: $border-regular solid $white;
          margin-right: 10px;
          white-space: nowrap;

          &:last-child {
            margin-right: 0;
          }
          &.current-menu-item, &.current-menu-parent {
            border: $border-regular solid black;
          }
          &:hover {
            border: $border-regular solid black;
          }

          @media (max-width:1441px) {
            font-size: 1.111rem;
            padding: 6px 25px 1px 25px;
          }

          &.menu-item-has-children:hover {

            .sub-menu {
              background: $white;
              left: -$border-regular;
              top: 100%;
              z-index: 10;
              opacity: 1;
              height: auto;
              padding-top: 0;
              color: black;
              background: $white;
              visibility: visible;
  
              li {
                border: $border-regular solid black;
                border-bottom: none;
                padding: 10px 30px;
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &:hover {
                  background: black;
                  color: $white;
                }
  
                &:last-child {
                  border-bottom: $border-regular solid black;
                }
  
                &.menu-item-has-children {
                  gap: 15px;

                  .sub-menu {
                    opacity: 0;
                    visibility: hidden;
                    height: 0;
                  }

                  &:after {
                    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='auto' height='13' viewBox='0 0 61 116' fill='none'><path d='M60.5 49.4478L0.5 0.5L0.499994,31.9794L34.3973,58.182L0.499985,84.2025L0.49998,115.5L60.5,66.5522L60.5,49.4478Z' fill='black'/></svg>");
                    display: inline;
                    font-family: "Font Awesome 6 Regular", sans-serif;
                    margin-top: -7px;
                  }
  
                  &:hover,
                  &.current-menu-item {
                    &::after {
                      content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='auto' height='13' viewBox='0 0 61 116' fill='none'><path d='M60.5 49.4478L0.5 0.5L0.499994,31.9794L34.3973,58.182L0.499985,84.2025L0.49998,115.5L60.5,66.5522L60.5,49.4478Z' fill='white'/></svg>");
                    }
                  }

                  &:hover {
                    .sub-menu {
                      opacity: 1;
                      height: auto;
                      visibility: visible;
                    }
                  }
                }
  
                .sub-menu {
                  position: absolute;
                  left: 100%;
                  top: -$border-regular;
                  opacity: 0;
                  height: 0;
                  visibility: hidden;
                }
              }
            }
          }
        }

        .sub-menu {
          opacity: 0;
          height: 0;
          width: max-content;
          position: absolute;
          visibility: hidden;

          li {
            margin-right: 0;

            &.current-menu-item {
              background-color: black;
              color: white;
            }

            @media (max-width:1441px) {
              font-size: 1.111rem;
              padding: 6px 25px 1px 25px;
            }
          }
        }
      }
    }
  }
}

// Breadcrumbs
.breadcrumbs {
  margin-top: 25px;
  margin-bottom: 60px !important;
  padding: 0 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  display: block !important;

  @media (max-width:767px) {
    margin-bottom: 40px !important;
  }

  p {
    margin-top: 0;
    span:nth-child(1) {
      a:nth-child(1) {
        width: fit-content;
        display: inline-block;

        &:after {
          display: block;
          content: "\0020";
          width: 105%;
          height: 3px;
          border-bottom: $border-regular solid black;
          transition: width .3s;
        }

        // &+span {
        //   a:after {
        //     display: none;
        //   }
        // }
      }
    }
  }
}

#mm-menu-main-container {
	--mm-color-background: white;
	--mm-color-text: #{$color-text};
	z-index: 2 !important;
	display: none !important;
  width: 100vw;
	transform: translateX(100%);
	height: calc(100vh - 116px);
	top: 116px;
	transition: all .3s ease-in-out;
	padding: 20px 0;
	line-height: normal;
	text-transform: none;
  font-size: inherit;

	&.mm-menu_opened {
		transform: translateX(0);
    display: flex !important;
	}

	.mm-panels {

		.mm-panel {
      padding: 0 15px;

			&::after {
				display: none;
				visibility: hidden;
			}
		}

		#mm-0 {
			.mm-navbar {
				display: none;
				visibility: hidden;
			}

      .header__nav--options {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        left: 0;
        margin-right: 0;
      }
		}

		.mm-navbar {
			color: $color-text;
			border-color: $color-text;
			text-align: left;
      border: none;
      margin-bottom: 25px;

			.mm-navbar__title {
				padding: 20px 0;
				justify-content: flex-start;
			}
		}
	}

	.mm-btn_next {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		border: none;

		&::after {
			width: 8px;
			height: 8px;
			border: 3px solid black;
      border-right: none;
      border-bottom: none;
		}
	}

	.mm-btn_prev {
    flex-grow: initial !important;
		&::before {
			width: 8px;
			height: 8px;
			border: 3px solid black;
      border-right: none;
      border-bottom: none;
		}
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			border-bottom: 2px solid $color-text;
			position: relative;

      &::after {
        display: none !important;
        visibility: hidden !important;
      }

			&.hidden {
				display: none;
				visibility: hidden;
			}

			a {
				padding: 15px 10px;
        display: block;
			}

			&:last-child {
				border-bottom: none;
			}

			&.current-menu-item,
			&.current-menu-ancestor,
			&.current-menu-parent,
			&.current-product-ancestor,
			&.current-product_cat-ancestor,
			&.current-page-ancestor {
				font-weight: 600;
			}
		}
	}

	.sub-menu {
		font-size: 18px;
	}

	#header-extras {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
		margin-top: 20px;

		&::after {
			display: none;
			visibility: hidden;
		}
	}

	.extra {
		display: inline-block;
		margin-right: 15px;
		font-size: 13px;
		line-height: normal;
		color: $color-text;
		font-weight: 700;
		width: fit-content;

		&:last-child {
			margin-right: 0;
		}

		i {
			color: $color-primary;
			font-size: 20px;
		}
	}
}
