.width-auto {
  .slick-track {
    img {
      height: 520px;
      object-fit: contain;
    }

    @media (max-width:576px) {

      img {
        height: 100%;
      }

      .slick-slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          height: 0;
        }
      }
    }
  }

  .slick-prev {
    left: 30px;
  }

  .slick-next {
    right: 30px;
  }
}


.slick-track {
  display: flex;
  align-items: center;

  img {
    height: 450px;
    width: 100%;
    object-fit: cover;

    @media (max-width:576px) {
      height: 250px;
    }
  }
}

.slick-prev, .slick-next {
  z-index: 10;
}
.slick-prev {
  border: solid black;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 8px;
  transform: rotate(135deg);
  height: 40px;
  width: 40px;
  left: -60px;

  &:before {
    display: none;
  }

  @media (max-width: 1199px) {
    width: 20px;
    height: 20px;
    border-width: 0 5px 5px 0;
  }
}

.slick-next {
  border: solid black;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 8px;
  transform: rotate(-45deg);
  height: 40px;
  width: 40px;
  right: -60px;

  &:before {
    display: none;
  }

  @media (max-width: 1199px) {
    width: 20px;
    height: 20px;
    border-width: 0 5px 5px 0;
  }
}
