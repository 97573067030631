.subpage--presentation {
  //Intro
  .intro {
    display: flex;
    gap: 100px;

    .intro__title {
      margin-bottom: 30px;
    }
    
    @media (max-width:991px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 50px;

      .intro__right {
        width: 100%;
      }
    }
  }

  //Team
  .team {
    margin-top: 100px;

    .team__title {
      margin-bottom: 30px;
    }
    .team__title, .team__text {
      width: 83%;

      @media (max-width:1199px) {
        width: 100%;
      }
    }
  }

  .team-bottom {
    margin-top: 100px;
    h5 {
      margin-bottom: 20px;
      margin-top: 50px;
    }
    p {
      margin: 0;
    }
  }
}