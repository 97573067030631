.page--exposition {
  overflow-x: hidden;
  .exposition {
    .introduction {
      display: grid;
      grid-template-columns: minmax(40%, 570px) 50%;
      gap: 8%;
      margin-bottom: 60px;

      @media (max-width:991px) {
        grid-template-columns: 100%;
        gap: 30px;
      }

      .introduction__poster {

        img {
          width: 100%;
          height: auto;
        }
        a {
          display: flex;
          gap: 10px;
          align-items: baseline;
          width: fit-content;
          span {
            color: #757575;
          }
        }
      }

      @media (max-width:991px) {

        .introduction__right {

          .breadcrumbs {
            display: none !important;
          }
        }
      }

      .introduction__title {
        margin-top: 55px;

        @media (max-width:991px) {
          margin-top: 0;
        }
      }
      .introduction__subtitle {
        padding-top: 15px;
      }
      .introduction__dates {
        padding: 15px 0 30px 0;
      }
      .introduction__tickets {
        margin-top: 20px;
        padding-left: 8px;

        @media (max-width:991px) {
          margin-top: 40px;
        }
      }

      .introductions__location-visibility {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 40px;
        .introduction__location {
          a {
            display: inline-block;
          }
        }
        .introduction__location, .introduction__visibility {
          display: flex;
          align-items: center;
          gap: 12px;
          background: $color-light-grey;
          padding: 20px;
        }
      }

      .introduction__video {
        margin-top: 20px;
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .accordions, .synopsis {
    width: 66.66%;

    @media (max-width:1199px) {
      width: 83%;
    }

    @media (max-width:991px) {
      width: 100%;
    }
  }

  .illustration {
    position: relative;
    .illustration--top {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -10;
      img {
        width: 300px;
        height: auto;
      }
    }

    @media (max-width:991px) {
      display: none;
    }
  }

  .illustration--bottom {
    margin-top: 50px;
    img {
      width: auto;
      height: 300px;
    }

    @media (max-width:991px) {
      display: none;
    }
  }
  .slider-section {

    margin-top: 90px;
    li {
      position: relative;
      .slider__legend {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%);
        z-index: 20;
      }
    }

  }
  .downloads {
    width: 91.6%;
    .downloads__title {
      margin-top: 105px;
      margin-bottom: 25px;
    }
    .downloads__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8.33%;
    }
  }
}