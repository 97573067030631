.subpage--pschit {
  margin-top: 70px;
  .paragraphs {
    margin-top: 100px;
    .paragraph__text {
      margin: 30px 0;
    }

    @media (max-width:991px) {
      margin-top: 50px;
    }
  }
}