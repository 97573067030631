.page--expositions {
  margin-top: 20px;

  @media (max-width:767px) {
    margin-top: 30px;
  }
  .expositions--current {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    list-style-type: none;
    gap: 30px;
    margin-top: 20px;

    @media (max-width:767px) {
      grid-template-columns: 100%;
      margin-top: 60px;
    }

    .exposition--current {
      width: 100%;

      a {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .expositions--archived__title {
    margin-top: 110px;
    margin-bottom: 35px;
  }

  .expositions__filter {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .expositions__filter__checkboxes {
      position: relative;
      bottom: 8px;
    }
    .checkbox {
      display: flex;
      align-items: center;
      gap: 35px;

      @media (max-width:767px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;
        margin-left: 35px;
      }
    }
    .checkbox--location,
    .checkbox--visibility {
      -webkit-appearance: none;
      &:checked {
        + label::before {
          box-shadow: inset 0 0 0 2px #fff;
          background-color: black;
        }
      }
      + label::before {
        content: "\0020";
        display: block;
        height: 22px;
        width: 22px;
        border: 2px solid black;
        border-radius: 5px;
        left: -32px;
      }
    }
    .checkbox--visibility {
      + label {
        position: relative;
        &:before {
          position: absolute;
        }
      }
    }
    .checkbox--location {
      + label {
        position: relative;
        &:before {
          position: absolute;
        }
      }
    }

    @media (max-width:1199px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 50px;
    }
  }

  .expositions--archived {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    list-style-type: none;
    gap: 30px;
    margin-bottom: 50px;

    @media (max-width:991px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width:767px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 25px;
    }

    .exposition--archived {
      a {
        height: 100%;
        width: 100%;
        display: block;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        &:hover {
          position: relative;

          &:after {
            content: "\0020";
            position: absolute;
            background: rgba(0, 0, 0, 0.7);
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
          }

          &:before {
            content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='auto' height='23' viewBox='0 0 61 116' fill='none'><path d='M60.5 49.4478L0.5 0.5L0.499994,31.9794L34.3973,58.182L0.499985,84.2025L0.49998,115.5L60.5,66.5522L60.5,49.4478Z' fill='white'/></svg>");
            position: absolute;
            font-family: "Font Awesome 6 Regular", sans-serif;
            bottom: 10px;
            right: 30px;
            z-index: 2;
          }
        }
      }

      &.hidden {
        display: none;
      }

      &.visible {
        display: block;
      }
    }
  }

  .expositions__button {
    display: flex;
    margin: 0 auto;
    justify-content: center;
  }

  .thumbnail {
    width: 83.33%;
    background: $color-light-grey;
    padding: 25px 8% 35px 25px;
    margin: 80px auto 0 auto;

    .thumbnail__flex {
      display: flex;
      align-items: center;
      gap: 25px;

      img {
        width: 45px;
        height: 45px;
      }

      .thumbnail__title {
        position: relative;
        top: 4px;
      }
    }

    .thumbnail__text {
      margin-left: 70px;
      margin-top: 15px;

      a {
        display: inline-block;
        width: fit-content;

        &:after {
          display: block;
          content: "\0020";
          width: 100%;
          height: 3px;
          border-bottom: $border-regular solid black;
          transition: width .3s;
        }

        &:hover {
          &::after {
            width: 0;
          }
        }
      }
    }

    @media (max-width:767px) {
      width: 100%;
      padding: 20px;
      padding-top: 30px;

      .thumbnail__flex {
        gap: 20px;

        img {
          width: 35px;
          height: 35px;
        }
      }

      .thumbnail__text {
        margin-left: 0;
      }
    }
  }
}
