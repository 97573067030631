.subpage--handicap {
  .left-side {
    .list-infos {
      display: flex;
      flex-direction: column;
      gap: 50px;
      margin-top: 70px;

      .info {
        display: flex;
        align-items: center;
        gap: 4.16%;

        @media (max-width:576px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 20px;
        }

        img {
          width: 70px;
        }

        a {
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 10px;
        }
      }

      .underline--hover {
        display: inline-block;
      }
    }
  }
}