.subpage--fondation {

  .content {
    width: 83%;
    margin: 0 auto;

    @media (max-width:991px) {
      width: 100%;
    }
  }

  .intro {
    p {
      margin-top: 0;

      + p {
        margin-top: 30px;
      }
    }
  }

  .foundation-board {
    h5 {
      margin-top: 30px;
    }

    p + p {
      margin-top: 10px;
    }
  }

  .file-download {
    margin-top: 45px;
  }

  .reports {
    .reports__title {
      margin-top: 100px;
      margin-bottom: 30px;

      @media (max-width:767px) {
        margin-top: 80px;
      }
    }

    .reports__gallery {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;

      @media (max-width:1199px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width:767px) {
        grid-template-columns: repeat(2, 1fr);
      }

      a {
        img {
          height: 100%;
        }

        &:hover {
          position: relative;

          &:after {
            content: "\0020";
            position: absolute;
            background: rgba(0, 0, 0, 0.7);
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
          }

          &:before {
            content: "\f33d";
            position: absolute;
            color: $white;
            font-family: "Font Awesome 6 Regular", sans-serif;
            bottom: 10px;
            right: 15px;
            z-index: 2;
          }
        }
      }
    }
  }
}