.subpage--boutique {
  .infos__products {
    margin-top: 140px;

    @media (max-width:767px) {
      margin-top: 80px;
    }
  }
  .infos__text {
    ul {
      padding-left: 30px;
      margin-top: 5px;
    }
  }
}