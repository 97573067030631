.gallery {
  .gallery__title {
    margin-bottom: 30px;
    margin-top: 100px;

    @media (max-width:767px) {
      margin-top: 80px;
    }
  }

  .gallery__text {
    width: 66.66%;

    @media (max-width:1199px) {
      width: 83%;
    }

    @media (max-width:991px) {
      width: 100%;
    }

    a {
      border-bottom: 1px solid black;
    }
  }
  .gallery__companies {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;

    @media (max-width:1441px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width:991px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width:767px) {
      grid-template-columns: 100%;
    }
  }
}