//** Font Faces Style **//
@font-face {
  font-family: 'Overpass Bold';
  src: url('../webfonts/overpass/Overpass-Bold.woff2') format('woff2'),
      url('../webfonts/overpass/Overpass-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  unicode-range: U+00d7,  U+00EE;
}
@font-face {
  font-family: 'Overpass';
  src: url('../webfonts/overpass/Overpass-Regular.woff2') format('woff2'),
    url('../webfonts/overpass/Overpass-Regular.woff') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+00d7,  U+00EE;
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  src: url("../webfonts/fontawesome/fa-brands-400.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-brands-400.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Duotone";
  src: url("../webfonts/fontawesome/fa-duotone-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-duotone-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Light";
  src: url("../webfonts/fontawesome/fa-light-300.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-light-300.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Regular";
  src: url("../webfonts/fontawesome/fa-regular-400.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-regular-400.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Solid";
  src: url("../webfonts/fontawesome/fa-solid-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-solid-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Thin";
  src: url("../webfonts/fontawesome/fa-thin-100.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-thin-100.ttf") format("truetype");
}
