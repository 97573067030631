.page--atelier {
  .atelier__flex {
    margin-top: 60px;
    display: grid;
    grid-template-columns: 66% 34%;
    gap: 60px;

    @media (max-width:991px) {
      grid-template-columns: 100%;
    }

    .atelier__flex--left {
      .atelier__desc {
        margin-bottom: 40px;
      }
      .atelier__infos {
        display: flex;
        flex-direction: column;

        .atelier__infos__flex {
          margin-bottom: 25px;
          display: flex;
          align-items: center;
          gap: 5px;
          .atelier__infos__title {margin: 0;}
          .atelier__infos__icon {
            img {
              width: 75px;
            }
          }
        }
      }
    }

    .atelier__flex--right {
      width: calc(100% - 60px);

      @media (max-width:991px) {
        width: 100%;
      }
      ul {
        margin-bottom: 30px;

        .workshop__date {
          display: block;

          .workshop__date__availability {
            margin-top: 10px;
            right: 0;
            max-width: 100%;
          }
        }
      }
    }
  }
}